import { NavigateFunction } from "react-router-dom";
import { ButtonData, ButtonId, UrlMappings } from "../components/core/types";

// Map button click (identifiers) to local urls
const mapping: UrlMappings = {
  idToLocalPathMapping: new Map([
    ["contact", "/home#contactus"],
    ["home", "/home"],
  ]),
};

// button ids match the mapping above.
export const menuItems: ButtonData[] = [
  { id: "home", label: "Home" }
];

export const footerMenuItems: ButtonData[] = [
  { id: "home", label: "Home" },
];

export const navigateTo = (
  id: ButtonId,
  navigate: NavigateFunction
) => {
  const url = mapping.idToLocalPathMapping.get(id);

  if (!url) {
    throw new Error(
      `No url found for button ${id} was it added to idToLocalPathMapping?`
    );
  }

  navigate(url);
};
