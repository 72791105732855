import styles from "./home.module.scss";
import sharedStyles from "./shared-page-styles.module.scss";

import { Box } from "@mui/system";
import { BasePageProps } from "../app/base-page";
import React, { useContext, useState } from "react";
import { I18NData_HomePage } from "../app-i18n";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderSpinner from "../components/core/ui/loader-spinner";
import { useEffect } from "react";
import { imagesLoadedHandler } from "../components/core/windowloadhandler";
import {
  ReactApplicationContext,
  updateApplicationContext,
} from "../app/application-ctx";
export interface HomePageProps extends BasePageProps {
  pageTranslations?: I18NData_HomePage;
}

function HomePage(props: HomePageProps) {
  updateApplicationContext(
    useContext(ReactApplicationContext),
    props,
    useLocation()
  );
  // page content / translations
  const i18n = props.pageTranslations;
  const [isLoading, setIsLoading] = useState(true);
  const imageRef = React.useRef(null);
  //  This will run one time after the component mounts

  const imageLoad = () => {
    if (imageRef.current !== null) {
      console.log(`Image loaded should now fade`);
      const thisImage: HTMLImageElement = imageRef.current as HTMLImageElement;
      thisImage.className=styles.mainImage
    }
  }

  useEffect(() => {
    return imagesLoadedHandler(
      () => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      },
      (e) => console.error(`Well shit dude`, e)
    );
  }, []);
  return (
    <Box className={sharedStyles.page}>
      {/* Loader */}
      {isLoading && (
        <div className={"centercontent"}>
          <LoaderSpinner></LoaderSpinner>
        </div>
      )}

      {!isLoading && (
        <div>
          <Box className={styles.imagebgContainer}>
            <Box className={styles.imagebg}>
              <img alt="Digital Brain Illustration" className={styles.hidden} ref={imageRef} onLoad={() => imageLoad()} src="https://cdn-decoded-software.s3.us-west-1.amazonaws.com/binary-code-small.jpg"></img>
            </Box>
          </Box>

          <Box className={sharedStyles.pagecontent} component="section" id="intro">
            <h1 className={sharedStyles.pageHeader}>{i18n?.pageHeading}</h1>

            <div className={sharedStyles.pageText}>
              <p>{i18n?.intro}</p>
            </div>
          </Box>


          <Box className={sharedStyles.pagecontent} component="section" id="team">
            <h1 className={sharedStyles.sectionHeader}>Meet The Team</h1>

            <Box className={styles.profileContainer}>
              <Box className={styles.profileImageContainer}>
                <img src="http://1.gravatar.com/avatar/20a43b8b29e5b93835a49b68154665f4/?s=175" className={styles.profileImage}></img>
              </Box>
              <Box className={styles.profileText}>
                <h2 className={styles.profileHeaderText}>Bow M. Archer</h2>
                <h3 className={styles.profileSubtitleText}>Staff Software Engineer</h3>
                <p className={sharedStyles.pageText}>
                  Bow Archer is a Software Engineer and Architect with over 20 years of real world experience. He has grown his software career working for Adobe, Electronic Arts, and LinkedIn as well as startups across many industries.
                </p>
              </Box>
            </Box>
          </Box>

        </div>
      )}
    </Box>
  );
}

export default function Home(props: HomePageProps) {
  const navigate = useNavigate();
  return <HomePage {...props} navigate={navigate} />;
}
